/* Stylesheet */
import "../scss/main.scss";

/* Scripts */
jQuery(document).ready(function () {
    productVariation();
    checkForProductGallerySlider();
    checkForProductsSlider();
    // mainMenuSizes();
    // filterCategoryFilter();
    checkOfferteBox();
    checkForUspSlider();
});

jQuery(window).resize(function () {});

jQuery(document).scroll(function () {});

jQuery(document).on("click", "button.add-to-quote", function (event) {
    // Disable default action
    event.preventDefault();

    // Get product data
    let productID = jQuery("form.variations_form").data("product_id");
    let productVariation = jQuery("form.variations_form input[name=variation_id]").val();
    let productVariationValue = jQuery("input[name=attribute_pa_staffel]:checked").val();
    let productQuantity = jQuery(".staffel-product-quantity [type='number']").val();
    let quoteItemRef = jQuery("input[name=quote-ref]").val() !== undefined ? jQuery("input[name=quote-ref]").val() : Math.random().toString(36).substring(2, 10);

    // Add product to quote session, save directly to session
    jQuery.ajax({
        url: addProduct.rest_url,
        type: "POST",
        contentType: "application/json",
        headers: {
            "X-WP-Nonce": addProduct.nonce,
        },
        data: JSON.stringify({
            product_id: productID,
            variation_id: productVariation,
            variation_value: productVariationValue,
            quantity: productQuantity,
            ref: quoteItemRef,
        }),
        success: function (response) {
            let cart = document.querySelector("a.quote-cart div");
            cart.innerHTML = Number(cart.innerHTML) + 1;
            showQuoteAddedMessage(response);
        },
    });
});

/* Functions */

function productVariation() {
    /* Check for product variation quantity input */
    if (jQuery(".staffel-product-quantity [type='number']").length > 0) {
        // Disable input field
        // jQuery(".staffel-product-quantity [type='number']").prop('disabled', true);

        let staffelQuantity;

        // Check if quote ref is set
        let quoteItemRef = jQuery("input[name=quote-ref]").val() !== undefined ? jQuery("input[name=quote-ref]").val() : Math.random().toString(36).substring(2, 10);

        // If quote ref is set, get product data from session
        let quoteProducts = getCookieValue("quote_products");

        // Check if there is a product in the quote with ref as key
        if (quoteProducts !== "") {
            let products = JSON.parse(quoteProducts);

            if (products[quoteItemRef] !== undefined) {
                // Set .attribute-pa_staffel [type='radio'] checked based on session data
                jQuery("input[name=attribute_pa_staffel][value=" + products[quoteItemRef]["variation_value"] + "]").prop("checked", true);

                jQuery("label.variation-option").removeClass("active");
                jQuery("input[name=attribute_pa_staffel][value=" + products[quoteItemRef]["variation_value"] + "]")
                    .parent()
                    .parent()
                    .addClass("active");

                // Set quantity from session
                staffelQuantity = products[quoteItemRef]["quantity"];
            }
        }

        // Update default quantity, get value from current selected variation radio button
        let selectedStaffel = jQuery(".attribute-pa_staffel [type='radio']:checked").val();
        if (staffelQuantity === undefined) {
            staffelQuantity = jQuery('.attribute-pa_staffel span.description[data-term="' + selectedStaffel + '"]').data("quantity");
        }

        jQuery(".staffel-product-quantity [type='number']").val(staffelQuantity);

        // Update quantity on variation change
        jQuery(".attribute-pa_staffel [type='radio']").change(function () {
            jQuery("label.variation-option").removeClass("active");
            jQuery(this).parent().parent().addClass("active");

            let selectedStaffel = jQuery(this).val();
            let staffelQuantity = jQuery('.attribute-pa_staffel span.description[data-term="' + selectedStaffel + '"]').data("quantity");
            let staffelTerm = jQuery('.attribute-pa_staffel span.description[data-term="' + selectedStaffel + '"]').data("stuks");

            document.querySelector(".staffel-product-quantity .quantity").style.setProperty("--data-stuks", '"' + staffelTerm + '"');
            jQuery(".staffel-product-quantity [type='number']").val(staffelQuantity);
        });

        document.querySelector(".variation-option.active input").click();

        // Update quantity on staffel-down button click
        jQuery(".staffel-product-quantity .staffel-down").click(function () {
            let currentQuantity = parseInt(jQuery(".staffel-product-quantity [type='number']").val());
            let selectedStaffel = jQuery(".attribute-pa_staffel [type='radio']:checked").val();
            let staffelQuantity = jQuery('.attribute-pa_staffel span.description[data-term="' + selectedStaffel + '"]').data("quantity");
            currentQuantity = currentQuantity - staffelQuantity;
            currentQuantity = currentQuantity < 1 ? 1 : currentQuantity;
            // if (currentQuantity > staffelQuantity) {
            jQuery(".staffel-product-quantity [type='number']").val(currentQuantity);
            // }
        });

        // Update quantity on staffel-up button click
        jQuery(".staffel-product-quantity .staffel-up").click(function () {
            let currentQuantity = parseInt(jQuery(".staffel-product-quantity [type='number']").val());
            currentQuantity = currentQuantity < 1 ? 1 : currentQuantity;
            let selectedStaffel = jQuery(".attribute-pa_staffel [type='radio']:checked").val();
            let staffelQuantity = jQuery('.attribute-pa_staffel span.description[data-term="' + selectedStaffel + '"]').data("quantity");

            jQuery(".staffel-product-quantity [type='number']").val(currentQuantity + staffelQuantity);
        });
    }
}

jQuery(".quote-overview").on("click", ".remove-product", function () {
    let productRef = jQuery(this).data("item");
    let productTitle = jQuery(".item-" + productRef + " .title h3").html();
    if (productref) {
        productTitle += " - " + jQuery(".item-" + productRef + " .title span").html();
    }
    if (confirm("Weet je zeker dat je product '" + productTitle + "' uit je offertedoos wilt verwijderen?")) {
        jQuery.ajax({
            url: removeProduct.rest_url,
            type: "POST",
            contentType: "application/json",
            headers: {
                "X-WP-Nonce": removeProduct.nonce,
            },
            data: JSON.stringify({
                ref: productRef,
            }),
            success: function (response) {
                jQuery(".item-" + productRef).remove();
            },
        });
    } else alert("gelukkig!");
});

function showQuoteAddedMessage(response) {
    jQuery("body").append(
        '<div id="product-added-modal"><div class="wrapper">' +
            "<div><span>" +
            jQuery(".product_title.entry-title").html() +
            "</span> is toegevoegd aan jouw offertedoos</div>" +
            '<div><a class="btn close"><span>Verder winkelen</span></a><a href="/offertedoos" class="btn dark show"><span>Bekijk offertedoos</span></a></div>' +
            "</div></div>"
    );
    setTimeout(() => {
        jQuery("#product-added-modal").remove();
    }, 3000);
}

jQuery("body").on("click", "#product-added-modal .close", function () {
    jQuery("#product-added-modal").remove();
});

function checkForProductGallerySlider() {
    if (jQuery(".product-gallery-slider").length > 0) {
        jQuery(".product-gallery-slider .slider").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            infinite: true,
        });
    }
}

function checkForProductsSlider() {
    jQuery(".products-slider").each(function () {
        jQuery(".products", this).slick({
            slidesToShow: 4,
            slidesToScroll: 4,
            arrows: true,
            dots: true,
            infinite: true,
            appendDots: jQuery(".slide-m-dots", this),
            prevArrow: jQuery(".slide-m-prev", this),
            nextArrow: jQuery(".slide-m-next", this),
            responsive: [
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    },
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });
    });
}

function checkForUspSlider() {
    if (jQuery(".usps-slider").length > 0) {
        jQuery(".usps-slider").slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 0,
            speed: 8000,
            cssEase: "linear",
            waitForAnimate: false,
            pauseOnFocus: false,
            pauseOnHover: false,
            responsive: [
                {
                    breakpoint: 680,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        speed: 6000,
                    },
                },
            ],
        });
    }
}
/* Cookies */
function getCookieValue(cname) {
    // cname is the cookie name (foo) which value you are after
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

window.checkBoxSearch = function checkBoxSearch(form) {
    let emptyCounter = 0;
    let errorCounter = 0;
    jQuery('input[type="number"]', form).each(function (index, obj) {
        // Check for valid input
        if (jQuery(this).val() !== "" && jQuery(this).val() < 100) {
            jQuery(this).parent().find(".error").html("Te kleine waarde. Heb je het in millimeters ingevuld?").show();
            errorCounter++;
        } else if (jQuery(this).val() !== "" && jQuery(this).val() > 5000) {
            jQuery(this).parent().find(".error").html("Heb je het in millimeters ingevuld? (Maximale waarde 5000)").show();
            errorCounter++;
        } else {
            jQuery(this).parent().find(".error").hide();
        }

        // Check for empty input, then add 1 to empty counter variable
        if (jQuery(this).val() === "") {
            emptyCounter++;
        }
    });

    // If all inputs are empty, show error message
    if (emptyCounter === jQuery('input[type="number"]', form).length) {
        jQuery(".error", form).html("Vul tenminste een van deze velden").show();
        errorCounter++;
    }

    // If no errors, submit form
    if (errorCounter === 0) {
        // Remove all empty input fields
        jQuery('input[type="number"]', form).each(function (index, obj) {
            if (jQuery(this).val() === "") {
                jQuery(this).attr("name", "").attr("disabled", "disabled");
            }
        });
        return true;
    } else {
        return false;
    }
};

function filterCategoryFilter() {
    // Extract the specific product category ID from the body class
    var bodyClasses = jQuery("body").attr("class").split(" ");
    var specificTermId = null;

    bodyClasses.forEach(function (className) {
        if (className.startsWith("term-")) {
            specificTermId = className.split("-")[1];
        }
    });

    if (specificTermId) {
        // Hide all list items initially
        jQuery(".wpc-filter-product_cat .wpc-filters-ul-list .wpc-checkbox-item").hide();

        // Show the specific product category and its children
        jQuery("#wpc-term-taxonomy-product_cat-" + specificTermId)
            .parentsUntil(".wpc-filters-ul-list")
            .show();
        jQuery("#wpc-term-taxonomy-product_cat-" + specificTermId).show();
        jQuery("#wpc-term-taxonomy-product_cat-" + specificTermId)
            .find(".wpc-checkbox-item")
            .show();

        // Hide direct parent category if it exists
        //jQuery('#wpc-term-taxonomy-product_cat-' + specificProductId).parents('.wpc-checkbox-item').first().children('.wpc-term-item-content-wrapper').hide();

        // Show the specific product category and its children
        var specificCategory = jQuery("#wpc-term-taxonomy-product_cat-" + specificTermId);
        specificCategory.parentsUntil(".wpc-filters-ul-list").show();
        specificCategory.show();
        specificCategory.find(".wpc-checkbox-item").show();

        // Hide all parent categories of the specific category
        specificCategory.parents(".wpc-checkbox-item").each(function () {
            jQuery(this).children(".wpc-term-item-content-wrapper").hide();
        });

        // Hide label of the specific category
        jQuery(".wpc-term-id-" + specificTermId + " > .wpc-term-item-content-wrapper").hide();
    }
}

// Toggle filter
jQuery(".product-filter-results-wrapper .toggle-filter").on("click", function () {
    jQuery(".search-filter-column").toggleClass("open");
    jQuery("body").toggleClass("category-filter-open");
});

// Close filter on clicking outside of it
jQuery(document).on("click", function (event) {
    if (!jQuery(event.target).closest(".search-filter-column").length && !jQuery(event.target).closest(".toggle-filter").length) {
        jQuery(".search-filter-column").removeClass("open");
        jQuery("body").removeClass("category-filter-open");
    }
    if (event.target.classList.contains("closer")) {
        jQuery(".search-filter-column").removeClass("open");
        jQuery("body").removeClass("category-filter-open");
    }
});

function checkOfferteBox() {
    if (jQuery(".different-delivery-address").length > 0) {
        // Check if delivery address checkbox is checked
        if (jQuery('.different-delivery-address input[type="checkbox"]').is(":checked")) {
            jQuery(".delivery-address-section").parent().show();
        } else {
            jQuery(".delivery-address-section").parent().hide();
        }
    }

    // Fill product list inout field with product data from session
    jQuery(".products-list textarea").val(jQuery("#product-list-for-form").html());
}

jQuery(".different-delivery-address").on("change", function () {
    checkOfferteBox();
});

/* Custom file upload */
jQuery("form #file-upload").on("change", function () {
    let file = jQuery(this).prop("files")[0];
    let fileName = file.name;
    jQuery(this).parent().find(".custom-file-select").html(fileName);
});

document.querySelectorAll(".box-form,.tape-form").forEach((form) => {
    form.addEventListener("submit", function (e) {
        e.preventDefault();

        let isValid = true;

        jQuery("input").next(".form-error").remove();

        // Validate Bedrijfsnaam
        if (jQuery(form.querySelector("#companyname")).val().trim() === "") {
            jQuery(form.querySelector("#companyname")).after('<div class="form-error">Bedrijfsnaam is verplicht.</div>').focus();
            return;
        }

        // Validate Naam
        if (jQuery(form.querySelector("#name")).val().trim() === "") {
            jQuery(form.querySelector("#name")).after('<div class="form-error">Naam is verplicht.</div>').focus();
            return;
        }

        // Validate Telefoonnummer
        if (jQuery(form.querySelector("#phone")).val().trim() === "") {
            jQuery(form.querySelector("#phone")).after('<div class="form-error">Telefoonnummer is verplicht.</div>').focus();
            return;
        } else if (form.querySelector("#phone").checkValidity() === false) {
            jQuery(form.querySelector("#phone")).after('<div class="form-error">Geldig telefoonnummer is verplicht.</div>').focus();
            return;
        }

        // Validate Email
        let email = jQuery(form.querySelector("#email")).val().trim();
        if (email === "") {
            jQuery(form.querySelector("#email")).after('<div class="form-error">Email is verplicht.</div>').focus();
            return;
        } else if (!validateEmail(email)) {
            jQuery(form.querySelector("#email")).after('<div class="form-error">Voer een geldig emailadres in.</div>').focus();
            return;
        }

        // If all validations pass, submit the form
        if (isValid) {
            this.submit();
        }
    });
});

// Email validation function
function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
}

document.querySelectorAll('input[name="srch"],input[name="s"]').forEach((input) => {
    input.addEventListener("input", function (e) {
        if (e.target.value.match(/(\d)x(\d)/i)) {
            e.target.value = e.target.value.replace(/(\d)x(\d)/i, "$1 x $2");
        }
    });
});

var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
        /* Toggle between adding and removing the "active" class,
    to highlight the button that controls the panel */
        this.classList.toggle("active");

        /* Toggle between hiding and showing the active panel */
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
            panel.style.display = "none";
        } else {
            panel.style.display = "block";
        }
    });
}

function updateRange(rangeContainer) {
    let minRange = rangeContainer.querySelector(".min-range");
    let maxRange = rangeContainer.querySelector(".max-range");
    let minProxy = rangeContainer.querySelector(".min_proxy");
    let maxProxy = rangeContainer.querySelector(".max_proxy");
    rangeContainer.querySelector(".price-slider").style.setProperty("--left", (minRange.value / (Number(maxRange.attributes.max.value) - Number(minRange.attributes.min.value))) * 100 + "%");
    rangeContainer.querySelector(".price-slider").style.setProperty("--right", 100 - (maxRange.value / (Number(maxRange.attributes.max.value) - Number(minRange.attributes.min.value))) * 100 + "%");
    minProxy.value = minRange.value;
    maxProxy.value = maxRange.value;
}

function updateProxy(rangeContainer) {
    let minRange = rangeContainer.querySelector(".min-range");
    let maxRange = rangeContainer.querySelector(".max-range");
    let minProxy = rangeContainer.querySelector(".min_proxy");
    let maxProxy = rangeContainer.querySelector(".max_proxy");
    minRange.value = minProxy.value;
    maxRange.value = maxProxy.value;
    updateRange(rangeContainer);
}

function attachRangeListeners() {
    document.querySelectorAll(".price-input-container").forEach((rangeContainer) => {
        let minRange = rangeContainer.querySelector(".min-range");
        let maxRange = rangeContainer.querySelector(".max-range");
        let minProxy = rangeContainer.querySelector(".min_proxy");
        let maxProxy = rangeContainer.querySelector(".max_proxy");

        updateRange(rangeContainer);

        minProxy.addEventListener("input", () => updateProxy(rangeContainer));
        maxProxy.addEventListener("input", () => updateProxy(rangeContainer));
        minRange.addEventListener("input", () => updateRange(rangeContainer));
        maxRange.addEventListener("input", () => updateRange(rangeContainer));
    });
}
attachRangeListeners();

let timeout;
let sideFilter = document.querySelector(".side-filter");
if (sideFilter) {
    sideFilter.addEventListener("input", function (e) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            page=1;
            fetchProducts();
        }, 500);
    });
}
document.querySelectorAll(".wpc-orderby-select").forEach((select) => {
    select.addEventListener("change", () => {
        page=1;
        fetchProducts();
    });
});

document.querySelectorAll(".reset").forEach((reset) => {
    reset.addEventListener("click", () => {
        document.querySelectorAll('.categories__sub-item input').forEach((input) => {
            input.checked = false;
        });
        page=1;
        fetchProducts();
    });
});

function fetchProducts() {
    let url = "/wp-json/dozenhandel/v1/products?";
    params = new URLSearchParams();
    if(document.querySelector('input[name="min_lengte_"]')){
        params.append("min_lengte", document.querySelector('input[name="min_lengte_"]').value);
        params.append("max_lengte", document.querySelector('input[name="max_lengte_"]').value);
        params.append("min_breedte", document.querySelector('input[name="min_breedte_"]').value);
        params.append("max_breedte", document.querySelector('input[name="max_breedte_"]').value);
        params.append("min_hoogte", document.querySelector('input[name="min_hoogte_"]').value);
        params.append("max_hoogte", document.querySelector('input[name="max_hoogte_"]').value);
    }

    let categories = document.querySelectorAll('input[name="product_cat[]"]:checked');
    let categoriesSelected = [];
    let parents = [];
    categories.forEach((category) => {
        params.append("product_cat[]", category.value);
        categoriesSelected.push(category.value);
        if ((parent = category.closest(".categories__item"))) {
            parents.push(parent.querySelector("input").value);
        }
    });
    parents = parents.filter((value, index) => parents.indexOf(value) === index);
    // params.append('product_cat[]', categoriesSelected);
    if (document.querySelectorAll(".categories__sub input:checked").length > 0) {
        parents.forEach((parent) => {
            params.append("parents[]", parent);
        });
    }

    let qualities = document.querySelectorAll('input[name="pa_kwaliteit[]"]:checked');
    qualities.forEach((quality) => {
        params.append("pa_kwaliteit[]", quality.value);
    });

    let colors = document.querySelectorAll('input[name="pa_kleur[]"]:checked');
    colors.forEach((color) => {
        params.append("pa_kleur[]", color.value);
    });

    let filters = document.querySelectorAll('input[name="filtering[]"]:checked');
    filters.forEach((filter) => {
        params.append("filtering[]", filter.value);
    });
    params.append('page',page);
    // console.log(params.toString());

    fetch(url + params.toString())
        .then((response) => response.json())
        .then((data) => {
            // console.log(data);
            buildNewUrl(params);
            document.querySelector("#product-filter-results .products").innerHTML = data.html;
            document.querySelector("#product-filter-results .products").insertAdjacentHTML("beforeend", data.pagination);
            
            document.querySelector(".side-filter").innerHTML = data.filter;
            document.querySelector(".woocommerce-result-count").innerHTML = data.results;
            document.querySelectorAll(".price-input-container").forEach((rangeContainer) => {
                attachRangeListeners();
            });
            listenPagination();
            document.querySelector(".berocket_lgv_widget .selected").click();
            console.log('clickie')
        });

    // Remove count from filter
    setInterval(function () {
        document.querySelectorAll(".is-term-count").forEach((count) => {
            let countParent = count.parentElement;
            count.remove();
            countParent.innerHTML = countParent.innerHTML.replace("()", "");
        });
    }, 300);
}

function listenPagination(){
    document.querySelectorAll(".woocommerce-pagination a.page-numbers").forEach((pagination) => {
        pagination.addEventListener("click", (e) => {
            e.preventDefault();
            let url= pagination.href;
            let pageOccurence= url.indexOf("/page/")
            
            if(pageOccurence !== -1) {
                let pageOccurenceEnd = url.indexOf("/", pageOccurence+6);
                page = url.substring(pageOccurence+6, pageOccurenceEnd);
            }  
            fetchProducts();
        });
    });
}
listenPagination();

let url= window.location.href;
let pageOccurence= url.indexOf("/page/")
let page=1;
if(pageOccurence !== -1) {
    let pageOccurenceEnd = url.indexOf("/", pageOccurence+6);
    page = url.substring(pageOccurence+6, pageOccurenceEnd);
}

function buildNewUrl(params) {
    let url = window.location.origin ;
    let categories = [];
    let parents = [];
    let colors = [];
    let qualities = [];
    let filtering= [];
    params.forEach((value, key) => {
        if (key == "product_cat[]") {
            categories.push(value);
        } else if (key == "parents[]") {
            parents.push(value);
        } else if (key == "pa_kleur[]") {
            colors.push(value);
        } else if (key == "pa_kwaliteit[]") {
            qualities.push(value);
        } else if (key =='filtering[]') {
            filtering.push(value);
        }
    });

    categories.sort();
    parents.sort();
    colors.sort();
    qualities.sort();
    filtering.sort();

    if (filtering.length > 0) {
        url += `/filtering/${filtering[0]}`;
    }else{
        url += `/shop`;
    }
    if (categories.length > 0) {
        url += `/categorie-${categories.join("-or-")}`;
    }

    if (colors.length > 0) {
        url += `/kleur-${colors.join("-or-")}`;
    }
    if (qualities.length > 0) {
        url += `/kwaliteit-${qualities.join("-or-")}`;
    }
    url += `/page/${page}/`;
    if (parents.length > 0) {
        url += `?${parents.map((parent) => `parents[]=${parent}`).join("&")}`;
    }
    window.history.replaceState(null, null, url);
}
